import { Card } from '@mui/material'
import { useGetSavedJobsQuery } from 'api'
import { Saves, NoSaves } from 'features'
import React from 'react'

export const App = () => {
  const {
    data: saves,
    isLoading: savesLoading,
    error: savesError,
  } = useGetSavedJobsQuery()
  if (savesLoading) {
    return null
  }

  return (
    <Card data-testid="savedJobsWidget">
      {saves?.length === 0 || savesError ? <NoSaves /> : <Saves />}
    </Card>
  )
}
