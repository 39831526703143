import React from 'react'
import {
  Button,
  CardActions,
  CardContent,
  List,
  Typography,
} from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import { Content } from 'types'
import { useGetSavedJobsQuery } from 'api'

import { Save } from './components'

const Saves = () => {
  const content = useEpiContent<Content>()
  const { data: saves } = useGetSavedJobsQuery()
  return (
    <>
      <CardContent data-testid="savedJobs">
        <Typography variant="h6" component={'h2'}>
          {content?.heading}
        </Typography>
        <List>
          {saves?.map((save, index) => (
            <Save
              isLast={index === saves.length - 1}
              item={save}
              key={save.id}
            />
          ))}
        </List>
      </CardContent>
      <CardActions>
        <Button
          data-testid="savedJobsLink"
          href={content?.linkUrl}
          size="small"
          variant="text"
        >
          {content?.linkText}
        </Button>
      </CardActions>
    </>
  )
}

export default Saves
