import React from 'react'
import { useEpiContent } from '@trr/app-shell-data'
import { date } from '@trr/frontend-datetime'
import {
  Box,
  CardActionArea,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { Content, Save as SaveInterFace } from 'types'

const Save = ({ item, isLast }: { item: SaveInterFace; isLast: boolean }) => {
  const content = useEpiContent<Content>()
  return (
    <CardActionArea
      data-testid="saveItem"
      href={`${content?.jobbPageLinkBase}${item.id}`}
    >
      <ListItem divider={!isLast} alignItems="flex-start">
        <ListItemText
          data-testid="saveTextBox"
          primaryTypographyProps={{
            component: 'h6',
            variant: 'subtitle1',
          }}
          primary={item.jobTitle}
          secondaryTypographyProps={{
            component: 'div',
          }}
          secondary={
            <>
              <Stack
                direction="row"
                divider={
                  <Box fontSize={13} color="neutral.main">
                    ●
                  </Box>
                }
                spacing={1}
              >
                <Typography
                  variant="body2"
                  color="text.primary"
                  component="span"
                >
                  {item.employerName}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.primary"
                  component="span"
                >
                  {item.locationName}
                </Typography>
              </Stack>
              {item?.deadline && (
                <Typography variant="caption" fontSize={14}>
                  {`Sista ansökningsdag ${date.format(item.deadline)}`}
                </Typography>
              )}
            </>
          }
        />
      </ListItem>
    </CardActionArea>
  )
}

export default Save
