import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react/'
import { getIdToken } from '@trr/app-shell-data'
import { GetSavesResponse, Save } from 'types'
import { getConfig } from 'utils'

const { API_URL } = getConfig()

const trrFetchBaseQuery = () =>
  fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      headers.set('authorization', `Bearer ${getIdToken()}`)
      headers.set('Content-Type', 'application/json')
      headers.set('Accept-Language', 'sv')
      return headers
    },
  })

const api = createApi({
  baseQuery: trrFetchBaseQuery(),
  endpoints: (builder) => ({
    getSavedJobs: builder.query<Save[], void>({
      query: () =>
        `/jobs/ads/saved?page=1&pageSize=3&orderBy=applicationdeadline%20asc&savedTo=Saved&notSavedTo=Applied`,
      transformResponse: (response: GetSavesResponse) =>
        response?.jobAds.map((i) => i.jobAd) ?? [],
    }),
  }),
})

export const { useGetSavedJobsQuery } = api

export default api
